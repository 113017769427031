import "./src/blue-library/styles/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import smoothscroll from 'smoothscroll-polyfill';



export const shouldUpdateScroll = () => {
  // eslint-disable-next-line no-undef
  const isAnchor = window.location.href.includes("#");
  if (!isAnchor) {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }
  return false
}

export const onClientEntry = () => {
  const envPath = `${process.env.ENV}`;
  // adding qualtrics survey by different env on entry once
  const uatInnerHtml = `
  (function(){var g=function(e,h,f,g){
  this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
  this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
  this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
  this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
  this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
  try{(new g(100,"r","QSI_S_ZN_81UHlHogQUMdVEG","https://zn81uhlhogqumdveg-blueinsurance.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_81UHlHogQUMdVEG")).start()}catch(i){}})();
  `
  const uatId = "ZN_81UHlHogQUMdVEG"
  const prodInnerHtml = `
  (function(){var g=function(e,h,f,g){
  this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
  this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
  this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
  this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
  this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
  try{(new g(100,"r","QSI_S_ZN_erDsP2rUh83RDVA","https://znerdsp2ruh83rdva-blueinsurance.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_erDsP2rUh83RDVA")).start()}catch(i){}})();
  `
  const prodId = "ZN_erDsP2rUh83RDVA"
  const qualtricsScript = window.document.createElement('script');
  const qualtricsDiv = window.document.createElement('div');
  qualtricsScript.type = 'text/javascript'
  qualtricsScript.innerHTML = envPath === "production" ? prodInnerHtml : uatInnerHtml
  qualtricsDiv.id = envPath === "production" ? prodId : uatId
  window.document.head.appendChild(qualtricsScript);
  window.document.head.appendChild(qualtricsDiv);
  
  // adding info bar
  const  infobarInnerHtml = `
  (function(){var g=function(e,h,f,g){
    this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
    this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
    this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
    this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
    this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
    try{(new g(100,"r","QSI_S_ZN_5b7BpBylTCAhvHU","https://zn5b7bpbyltcahvhu-blueinsurance.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_5b7BpBylTCAhvHU")).start()}catch(i){}})();
  `
  const  infobarId = "ZN_5b7BpBylTCAhvHU"
  const infobarScript = window.document.createElement('script');
  const infobarDiv = window.document.createElement('div');
  infobarScript.type = 'text/javascript'
  infobarScript.innerHTML = infobarInnerHtml
  infobarDiv.id = infobarId
  window.document.head.appendChild(infobarScript);
  window.document.head.appendChild(infobarDiv);
  
 const first = window.document.head.firstChild
  const googleAnalyFunctionTag = document.createElement("script");
  const googleAnalyFunction = "(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date; h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')}; (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c ;})(window,document.documentElement,'async-hide','dataLayer',4000,{'OPT-M2MN69G':true});";
  googleAnalyFunctionTag.append(googleAnalyFunction);
  const googleAnalyStyleTag = document.createElement("style");
  const googleAnalyStyle = ".async-hide { opacity: 0 !important} ";
  googleAnalyStyleTag.append(googleAnalyStyle);
  window.document.head.appendChild(googleAnalyStyleTag);
  window.document.head.appendChild(googleAnalyFunctionTag);
  window.document.head.insertBefore(googleAnalyFunctionTag, first)
  window.document.head.insertBefore(googleAnalyStyleTag, first)

  const para = document.createElement("script");
  // eslint-disable-next-line no-undef
  const att = document.createAttribute("src");
  att.value = "https://www.googleoptimize.com/optimize.js?id=OPT-M2MN69G"
  para.setAttributeNode(att)
  // eslint-disable-next-line no-undef
  // eslint-disable-next-line no-undef
  window.document.head.appendChild(para)
  // eslint-disable-next-line no-undef
  window.document.head.insertBefore(para, first)

  /* SIS-734 issue(2) */
  const messagerScript = document.createElement("script");
  const messengerAtt = document.createAttribute("src");
  messengerAtt.value = "https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1";
  messagerScript.setAttributeNode(messengerAtt);
  window.document.head.appendChild(messagerScript);

  smoothscroll.polyfill();
}